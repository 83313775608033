import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import ScrollToTopButton from "components/common/ScrollToTopButton";

import { PasswordRecoveryDialog } from "components/special-pages/PasswordRecovery/PasswordRecoveryDialog";
import { ForgotPasswordDialog } from "components/special-pages/ForgotPasswordDialog";
import ConfirmationPage from "components/special-pages/ConfirmationPage";
import CancelEmail from "components/special-pages/CancelEmail";
import BlockedAccessPage from "components/special-pages/BlockedAccessPage";
import WrongUrl from "components/special-pages/WrongUrl";
import { SessionExpired } from "components/special-pages/SessionExpired";
import { Front } from "components/special-pages/Front";
import PrivacyPolicyPage from "./special-pages/PrivacyPolicy/";
import TermsPage from "./special-pages/Terms/";

import MyProfile from "components/pages/MyProfile";

import { getPageForLocation, pages } from "utils/pages";
import { lazyComponent } from "utils/pageLoading";
import Footer from "components/layout/Footer";
import bootstrap from "bootstrap";
import NotificationsBanner from "./layout/Notifications";

import cx from 'classnames';
import ErrorBoundary from "./shared/ErrorBoundary";
import { Blankholder, Button, Icon, Loader } from "./shared";
import { DialogRoute } from 'components/shared/Dialog';
import { TopBar } from "./layout/TopBar";
import { MainSidebar } from "./layout/Sidebar";
import { emptyArray } from "../utils/constants";
import { howManyDaysTill } from "../utils/globalFunctions";
import { Tran, tran } from "../utils/language";
import { useDialog } from "./shared/Dialog";
import { PasswordChangeDialogLoader } from "./pages/Admin/Users/SingleUser";
import { AppBar } from "./shell/appbar/AppBar";
import { useShellContext } from "./shell/shellContext";
import { useCurrentUser } from "utils/hooks/redux";
import { AccessDenied, LoginRequired } from "./shell/shellAuth";
import { SessionOverload } from "./special-pages/SessionOverload";

const AdminLoader = lazyComponent(() => import(/* webpackChunkName: "adm" */ "components/pages/Admin"));

const TekaLoader = lazyComponent(() => import(/* webpackChunkName: "teka" */ "components/pages/Teka"));

const JournalLoader = lazyComponent(() => import(/* webpackChunkName: "jrnl" */ "components/pages/Journal"), "JournalApp");

const CataloguingLoader = lazyComponent(() => import(/* webpackChunkName: "ctg" */ "components/pages/Cataloguing"), "CataloguingApp");

const LendingLoader = lazyComponent(() => import(/* webpackChunkName: "lend" */ "components/pages/Lending"), "LendingApp");

const WorkstationsLoader = lazyComponent(() => import(/* webpackChunkName: "work" */ "components/pages/Workstations"), "WorkstationsApp");

const BookLockerLoader = lazyComponent(() => import(/* webpackChunkName: "lckr" */ "components/pages/BookLocker"), "BookLockerApp");

const WeedingLoader = lazyComponent(() => import(/* webpackChunkName: "weed" */ "components/pages/Weeding"), "WeedingApp");


// TODO: Nowa struktura katalogów i paczek?
//       * shell - rama aplikacji
//       * user  - wszystko dot. zalogowanego usera
//       * admin
//         * admin/main
//         * admin/teka
//         * admin/sowa
//       * teka
//         * teka/edit - kod tylko dla redaktorów i personelu
//       * lib   - współdzielone libki (bez osobnej paczki)
//       * comps - współdzielone komponenty (bez osobnej paczki)


export const AppContainer = () => {
  // 'centrum' aplikacji, zawiera w sobie wszystkie podstrony

  // WAŻNE
  // strony, które zawierają dalsze podstrony (np. users) nie mogą zawieracc'exact' w parametrach, ponieważ zablokuje to dostęp do tych podstron
  // pozostałe powinno to miec

  let content;
  
  const shellContext = useShellContext();
  const currentUser = useCurrentUser();
  const sessionExpired = useSelector(sessionExpiredSel);
  const sessionMessage = useSelector(sessionMessageSel);
  const location = useLocation();
  
  const app = getPageForLocation(location.pathname, 1);
  
  // TODO: przyjrzeć się temu
  if (sessionExpired) {
    content = <SessionExpired />;
  }  else if (sessionMessage) {
    content = <SessionOverload message={sessionMessage} />;
  } else {
    content = APP_CONTENT;
  }
  
  // TODO: app.checkRequirements tutaj?
  
  if (app.fullscreen && !sessionExpired) {
    const isLoaded = !currentUser.loading;
    const isAuthorised = app.checkRequirements(shellContext.requirementsMet);

    if (!isLoaded && !isAuthorised) content = <Loader />;
    else if (!isAuthorised) {
      if (currentUser.folks.user_id) content = <AccessDenied />;
      else content = <LoginRequired />;
    }

    return <>
      <MainSidebar />
      <div className={`global-container app-${app.id} fullscreen`}>
        <AppBar app={app.id} disabled={!isAuthorised} />
        <ErrorBoundary fallback={message => <ErrorContent message={message} />} resetKey={location /* Zmiana adresu resetuje błąd */}>
          {content}
        </ErrorBoundary>
      </div>
    </>;
  }

  return (
    <>
      <MainSidebar />
      <div className={`global-container app-${app.id}`}>
        <TopBar />
        {/*<div className="topbar-spacer" />*/}
        {warningBar}
        <div className={cx("main-container global-padding", { "WZUW": bootstrap.WZUW }, { "Front": location.pathname === "/" })}>
          <ErrorBoundary fallback={message => <ErrorContent message={message} />} resetKey={location /* Zmiana adresu resetuje błąd */}>
            {content}
          </ErrorBoundary>
        </div>
        {bootstrap.no_footer ? null : <Footer />}
        <ScrollToTopButton />
      </div>
    </>
  );
};

const overlayScrollbarOptions = { overflow: { x: "hidden" }, scrollbars: { autoHide: "move", autoHideDelay: 500 } };

function WarningBar() {
  const loggedIn = useSelector(isUserLoggedIn);
  const expiry = useSelector(passwordExpiry);
  const days = useSelector(expirationWarningDays);
  const [scrolledDown, setScrolledDown] = useState(false);
  const passwordChangeDialog = useDialog(PasswordChangeDialogLoader);
  
  useEffect(() => {
    function onScroll(ev) {
      const gc = document.querySelector(".global-container");
      if (gc)
        setScrolledDown(gc.scrollTop >= 96);
    }
    
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  }, emptyArray);
  
  if (loggedIn && expiry && days) {
    const daysToExpiration = howManyDaysTill(expiry); // liczba dni do wygaśnięcia hasła
    if (daysToExpiration <= days) {
      let msg =
        daysToExpiration < 0
          ? tran("topbar.passExpiry.expired")
          : daysToExpiration === 0
            ? tran("topbar.passExpiry.expiredInDay")
            : daysToExpiration === 1
              ? tran("topbar.passExpiry.tomorrow")
              : <Tran id="topbar.passExpiry.multiple" search="<<1>>" replace={daysToExpiration}/>;
      
      return <>
        <button key="warning-bar" className="rst global-padding warning-bar" onClick={passwordChangeDialog.open}>
          {warningIcon}
          {" "}
          {msg}
        </button>
        <button key="warning-float" tabIndex={-1} className={`warning-float ${scrolledDown ? "" : "hidden"}`} onClick={scrollToTop}>{warningIcon}</button>
      </>;
    }
  }
  
  return null;
}

const isUserLoggedIn = state => state.session.isUserLoggedIn;
const passwordExpiry = state => state.currentUser.folks.expiry;
const sessionMessageSel = state => state.session.sessionMessage;
const sessionExpiredSel = state => state.session.sessionExpired;
const expirationWarningDays = state => +(state.utils.appSettings.passwordExpirationWarning || 5);
const warningIcon = <Icon name="exclamation triangle"/>;
const warningBar = <WarningBar/>;

function scrollToTop(ev) {
  ev.preventDefault();
  document.querySelector(".global-container")?.scrollTo({ top: 0, behavior: "smooth" });
}

let FRONT;
switch (bootstrap.home) {
  case "teka":
    FRONT = <Redirect path="/" exact to="/teka/index" />
    break;
  case "prasa":
    FRONT = <Redirect path="/" exact to="/teka/prasa" />
    break;
  default:
    FRONT = <Route path="/" exact component={Front} />
}
// Centralny routing
// <Switch> wybierze pierwszą pasującą dyrektywę
const ROUTING_SWITCH = <Switch>
  <Route
    path={pages.teka.fullPath}
    render={() => {
      if (window.bootstrap.teka && window.bootstrap.teka.url) {
        return <TekaLoader />;
      } else {
        return <Redirect to="/" />;
      }
    }}
  />

  <Route
    path={pages.admin.fullPath}
    component={AdminLoader}
  />

  {pages.cataloguing && <Route
    path={pages.cataloguing.fullPath}
    component={CataloguingLoader}
  />}
  
  {pages.czyt && <Route
    path={pages.czyt.fullPath}
    component={JournalLoader}
  />}

  {pages.wyp && <Route
    path={pages.wyp.fullPath}
    component={LendingLoader}
  />}

  {pages.stan && <Route
    path={pages.stan.fullPath}
    component={WorkstationsLoader}
  />}

  {pages.ubyt && <Route
    path={pages.ubyt.fullPath}
    component={WeedingLoader}
  />}

  {pages.locker && <Route
    path={pages.locker.fullPath}
    component={BookLockerLoader}
  />}
  

  <Route path={pages.myProfile.fullPath} component={MyProfile} />

  <Route path="/accessDenied" exact component={BlockedAccessPage} />

  {FRONT}

  <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />

  <DialogRoute path="/recovery" component={PasswordRecoveryDialog} />

  <DialogRoute path="/forgot" component={ForgotPasswordDialog} />

  <Route path="/confirm" component={ConfirmationPage} />

  <Route path="/cancel" exact component={CancelEmail} />

  <Route path="/terms" exact component={TermsPage} />
  
  <Redirect from="/journal" to="/czyt" exact push={false} />

  <Route component={WrongUrl} />
</Switch>;
  
const APP_CONTENT = <>
  <NotificationsBanner />
  {ROUTING_SWITCH}
</>;
  
function ErrorContent({ message }) {
  const errorBoundary = ErrorBoundary.useApi();
  return <div>
    <Blankholder icon="exclamation circle" description={message}/>
    <Button.Group align="center">
      <Button preset="refresh" onClick={errorBoundary.clearError}/>
    </Button.Group>
  </div>
}