import {
  SESSION_SUCCESS,
  SESSION_ERROR,
  LOGOUT_SUCCESS,
  SESSION_EXPIRATION_UPDATED,
  UPDATE_SESSION_INFO, ACTIVITY_CHANGE
} from "../sagas/types";

import { localThemeReset } from "../utils/themes";

const initialState = {
  isUserLoggedIn: false,
  anonymousSession: false,
  session_id: null,
  session_key: null,
  expires_at: null,
  sessionExpired: false,
  sessionError: null,
  sessionLoading: false,
  activity: null,
  timeout: -1 // czas żywotności sesji w minutach
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_SUCCESS: {
      return {
        ...state,
        session_id: action.payload.session_id,
        session_key: action.payload.session_key,
        anonymousSession: action.payload.anonymous,
        isUserLoggedIn: !action.payload.anonymous,
        expires_at: action.payload.expires_at || null,
        sessionError: null,
        sessionExpired: action.payload.anonymous ? state.sessionExpired : false,
        sessionMessage: action.payload.message,
        timeout: action.payload.timeout || -1,
        logoutUrl: action.payload.logoutUrl,
      };
    }

    case UPDATE_SESSION_INFO: {
      return {
        ...state,
        ...action.payload
      };
    }

    case SESSION_ERROR: {
      return {
        ...state,
        session_id: null,
        session_key: null,
        anonymousSession: false,
        isUserLoggedIn: false,
        sessionError: action.payload
      };
    }
    
    case LOGOUT_SUCCESS: {
      localThemeReset();
      return {
        ...state,
        session_id: null,
        session_key: null,
        anonymousSession: false,
        isUserLoggedIn: false,
        expires_at: null,
        timeout: -1,
        sessionExpired:
          action.payload && action.payload.sessionExpired ? true : false,
        sessionMessage: null  
      };
    }

    case SESSION_EXPIRATION_UPDATED: {
      return {
        ...state,
        expires_at: action.payload.expires_at
      };
    }
    
    case ACTIVITY_CHANGE: {
      return {
        ...state,
        activity: action.payload,
      }
    }
    
    default: {
      return state;
    }
  }
}
